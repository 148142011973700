<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        <Button
          label="اضافة جديد"
          icon="pi pi-plus"
          v-tooltip="'اضافه جديد'"
          class="p-ml-2 p-button-success"
          v-if="$checkRoles('purchasesAdd')"
          @click="$router.push('purchases/add')"
        />
        <Button
          label="حذف"
          icon="pi pi-trash"
          v-tooltip="'حذف'"
          @click="deleteAll"
          v-if="$checkRoles('purchasesDelete')"
          :disabled="selectedItems.length == 0"
          class="p-button-danger"
        />
      </template>

      <template #right>
        <Button
          icon="pi pi-download"
          class="p-button-help"
          @click="exportCSV($event)"
          v-tooltip="'طباعه'"
        />
      </template>
    </Toolbar>

    <DataTable
      ref="dt"
      :value="list"
      :paginator="true"
      :expandedRows.sync="expandedRows"
      class="p-datatable-customers table table-striped"
      :rows="10"
      dataKey="id"
      :rowHover="true"
      :loading="loading"
      :filters="filters"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50, 100, 500, 1000, 10000]"
      currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
    >
      <template #header>
        <div class="table-header">
          قائمه اوامر الشراء
        </div>
      </template>
      <template #empty>
        لا يوجد بيانات
      </template>
      <template #loading>
        يتم تحميل البيانات. يرجي الانتظار...
      </template>
      <Column selectionMode="multiple" field="id" headerStyle="width: 3em" />
      <Column
        field="code"
        header="الكود"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="number"
            v-model="filters['code']"
            class="p-column-filter"
            placeholder="بحث بالكود"
          />
        </template>
      </Column>
      <Column field="clientsId.name" header="العميل" :sortable="true">
        <template #filter>
          <select
            class="form-select form-control p-column-filter"
            id="clientsId"
            name="clientsId"
            v-model="filters['clientsId.name']"
            placeholder="بحث بالعميل"
          >
            <option :value="null">الكل </option>
            <option
              v-for="item of clientsList"
              :key="item.id"
              :value="item.name"
              >{{ item.name }}</option
            >
          </select>
        </template>
      </Column>
      <Column
        field="usersId.name"
        header="مسئول المبيعات"
        :sortable="true"
        v-if="$checkRoles('purchasesAll')"
      >
        <template #filter>
          <select
            class="form-select form-control p-column-filter"
            id="usersId"
            name="usersId"
            v-model="filters['usersId.name']"
            placeholder="بحث بمسئول المبيعات"
          >
            <option :value="null">الكل </option>
            <option
              v-for="item of usersList"
              :key="item.id"
              :value="item.name"
              >{{ item.name }}</option
            >
          </select>
        </template>
      </Column>

      <Column field="warehouseId.name" header="المستودع" :sortable="true">
        <template #filter>
          <select
            class="form-select form-control p-column-filter"
            id="warehouseId"
            name="warehouseId"
            v-model="filters['warehouseId.name']"
            placeholder="بحث بالمستودع"
          >
            <option :value="null">الكل </option>
            <option
              v-for="item of warehouseList"
              :key="item.id"
              :value="item.name"
              >{{ item.name }}</option
            >
          </select>
        </template>
      </Column>

      <Column field="date" header="التاريخ" :sortable="true">
        <template #body="slotProps">
          {{ slotProps.data.date }}
        </template>
      </Column>

      <Column field="taskTime" header="وقت التنفيذ" :sortable="true">
        <template #body="slotProps">
          {{ durationFormat(slotProps.data.taskTime) }}
        </template>
      </Column>
      <Column
        field="id"
        header="تم الطلب"
        v-if="$checkRoles('purchasesOrderDone')"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          <InputSwitch
            v-tooltip="'تم الطلب'"
            v-model="slotProps.data.orderDone"
            :disabled="slotProps.data.orderDone"
            @change="updateStatusOne(slotProps.data)"
          />
          <br />
          {{ $durationFormatFull(slotProps.data.orderDoneDate) }}
        </template>
      </Column>

      <Column
        field="id"
        header="تم الاستلام"
        v-if="$checkRoles('purchasesOrderDelivery')"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          <InputSwitch
            v-tooltip="'تم الاستلام'"
            v-model="slotProps.data.orderDelivery"
            :disabled="
              slotProps.data.orderDelivery || !slotProps.data.orderDone
            "
            @change="updateStatusTwo(slotProps.data)"
          />
        </template>
      </Column>

      <Column field="id" bodyStyle="text-align: center; overflow: visible">
        <template #body="slotProps">
          <Button
            type="button"
            @click="$router.push('purchases/edit/' + slotProps.data.id)"
            icon="pi pi-pencil"
            v-tooltip="'تعديل'"
            v-if="$checkRoles('purchasesEdit')"
            class="p-button-success p-ml-2  p-button-rounded"
          ></Button>
          <Button
            type="button"
            icon="pi pi-trash"
            v-tooltip="'حذف'"
            v-if="$checkRoles('purchasesDelete')"
            @click="deleteItem(slotProps.data.id)"
            class="p-button-warning  p-button-rounded"
          ></Button>
        </template>
      </Column>

      <Column :expander="true" headerStyle="width: 3rem" />

      <template #expansion="slotProps">
        <div class="orders-subtable" v-html="slotProps.data.body"></div>
      </template>
    </DataTable>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  data() {
    return {
      list: [],
      selectedItems: [],
      loading: true,
      filters: {},
      clientsList: [],
      usersList: [],
      warehouseList: [],

      expandedRows: [],
    };
  },
  methods: {
    durationFormat(value) {
      const duration = moment(new Date(value));
      return duration.format('hh:mm A');
    },
    getData() {
      if (this.$checkRoles('purchasesAll')) {
        const user = JSON.parse(localStorage.admin);
        this.$http.get(`purchases?orderDelivery=0&usersId=` + user.id).then(
          (response) => {
            this.loading = false;
            this.list = response.data;
          },
          (err) => {
            this.loading = false;
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      } else {
        this.$http.get(`purchases?orderDelivery=0`).then(
          (response) => {
            this.loading = false;
            this.list = response.data;
          },
          (err) => {
            this.loading = false;
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      }
    },

    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    updateStatusOne(data) {
      data.orderDoneDate = new Date();
      this.updateStatus(data);
    },
    updateStatusTwo(data) {
      data.orderDeliveryDate = new Date();
      this.updateStatus(data);
    },
    updateStatus(data) {
      this.$http.put(`purchases/${data.id}`, data).then(
        () => {
          this.getData();
          this.$toast.add({
            severity: 'success',
            summary: 'تم بنجاح',
            detail: 'تم التعديل بنجاح',
            life: 3000,
          });
        },
        (err) => {
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },

    deleteAll() {
      this.$confirm.require({
        message: 'هل انتا متاكد انك تريد حذف السجلات',
        header: 'تأكيد الحذف',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$http
            .delete(`purchases/deleteList`, {
              idList: this.selectedItems,
            })
            .then(
              () => {
                this.getData();
                this.$toast.add({
                  severity: 'error',
                  summary: 'تم بنجاح',
                  detail: 'تم الحذف بنجاح',
                  life: 3000,
                });
              },
              (err) => {
                this.$toast.add({
                  severity: 'error',
                  summary: 'هناك خطأ',
                  detail: err.response.data.message,
                  life: 3000,
                });
              },
            );
        },
      });
    },
    deleteItem(id) {
      this.$confirm.require({
        message: 'هل انتا متاكد انك تريد حذف السجلات',
        header: 'تأكيد الحذف',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$http.delete(`purchases/${id}`).then(
            () => {
              this.getData();
              this.$toast.add({
                severity: 'error',
                summary: 'تم بنجاح',
                detail: 'تم الحذف بنجاح',
                life: 3000,
              });
            },
            (err) => {
              this.$toast.add({
                severity: 'error',
                summary: 'هناك خطأ',
                detail: err.response.data.message,
                life: 3000,
              });
            },
          );
        },
      });
    },
  },
  created() {
    if (!this.$checkRoles('purchasesVeiw')) {
      this.$router.push('/admin/');
    }
    this.$http.get(`clients`).then((res) => {
      this.clientsList = res.data;
    });
    const user = JSON.parse(localStorage.admin);

    this.$http.get(`warehouse`).then((res) => {
      for (const item of res.data) {
      }
      this.warehouseList = res.data.filter((itm) =>
        itm.users.find((u) => u.id == user.id),
      );
    });

    this.$http.get(`users`).then((res) => {
      this.usersList = res.data;
    });
    this.getData();
  },
};
</script>

<style>
.col-form-label {
  text-align: start;
}
</style>
